<template>
  <v-app>
    <v-app-bar
      app
      color="blue"
      dark
      class="cursive"
      style="font-size:1.5em"
    > 
    <img src="https://thebuddhapath.eu/sites/all/themes/buddhapath/logo.png" style="height:100%" class="pr-5"/>
      Buddha Path party game
    </v-app-bar>

    <v-main>
      <div id="parent">
        <v-container>
          <v-row class="text-center">
            <v-col cols="12">
              <v-card :color="currentColor">
                <v-card-subtitle id="action-instruction" pt-5>{{ currentCard.action }}</v-card-subtitle>
                <div style="height: 20px"></div>
                <v-card-text pt-10 class="cursive" style="font-size:2.5em" v-html="currentCard.word"></v-card-text>
                <div style="height: 35px"></div>
              </v-card>
            </v-col>
            <v-col>
              <v-btn @click="pickCard()" dark color="blue">New card</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-main>
  </v-app>
</template>

<script>

export default {
  data: () => ({
    cards: makeCards(),
    currentCard: {action: "", word: "Click the button below!"}
  }),
  computed: {
    currentColor () {
      return actionColorMap[this.currentCard.action]
    }
  },
  methods: {
    pickCard() {
      if (this.cards.length > 0) { 
        this.currentCard = popRandom(this.cards)
      } else {
        this.currentCard = {action: "", word: "Out of cards.<br><br>Refresh the page to start again."}
      }
    }
  }
}

function popRandom(arr) {
  const index = Math.floor(Math.random()*arr.length)
  const result = arr[index]
  arr.splice(index, 1)
  return result
}

function makeCards() {
  let result = []
  for (const [action, words] of Object.entries(cards)) {
    for (let word of words) {
      result.push({action: action, word: word})
    }
  }
  return result
}

let actionColorMap = {
  "": "white",
  "Draw!": "green",
  "Act!": "red",
  "Explain!": "yellow"
}

let cards = {
  "Draw!": ["Birth", "Manjushri","Prostrations","White Tara", "Vajra","Austria", "Buddha Path", "Mandala", "Buddha","Sheep","Blue","Avalokiteshvara", "Shakyamuni","India","Wood","Estonia","Netherlands","DRCE", "Bodhisattva", "Lion throne","Forest", "Hungry ghost realm", "Purification","Birth", "Green Tara", "Human realm","Green"],
  "Act!": ["Compassion","Path", "No ego", "Animal realm", "Life is suffering", "Cold pride","Hot pride", "Dharmapala","Interdependence", "Bodhicitta", "Bodhisattva","Human life is precious", "White", "Bell", "Mantra", "Samadhi", "Dharma", "Sangha", "Math", "Mountain", "Lotus flower", "Dharma activity","Cat", "Karma", "Old age", "Enlightement", "Hill", "Yoga", "Mudra", "God realm"],
  "Explain!": ["Wordly goals", "Nalanda", "Life is impermanent", "Shrine", "Lokayana", "Ripenining karma", "Death", "Cause and effect are infallible", "Physics", "4 noble truths", "Hell realm", "Ripenened karma", "5 qualities of ego", "5 excellent gathered perfections", "4 immeasuables", "Demigod realm", "Result", "Monastery", "Merit", "ITTP", "Red", "Ground", "Cow", "Shantideva", "Metaphysics", "Sangha Mitra", "Dharma Acharya", "Bodhi Lama", "Obscuration", "Monk", "The Noble Eightfold Path", "Non-above", "Arhat", "Pratyekabuddha", "Pop culture", "Excellence"], 
}

</script>

<style scoped>
.container {
  max-width: 700px;
}

#parent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 70%;
}

#action-instruction {
  text-align: left;
  font-style: italic;
  font-size: 1.5em;
}

.cursive {
  font-family: 'Courgette', cursive;
}
</style>
